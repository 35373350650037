var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.back()}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('v-toolbar-title',[_vm._v("Struktura")]),_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","dark":"","color":"green","to":{ name: 'panel.company-structure.add' },"exact":""}},[_c('span',{staticClass:"d-none d-md-inline"},[_vm._v("Dodaj strukturę")]),_c('span',{staticClass:"d-md-none"},[_vm._v("Dodaj")]),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-plus")])],1)],1),(_vm.hasAccessTo('employee') || _vm.hasManyCompanies())?[_c('v-divider'),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"4","xl":"3"}},[_c('m-company-filter',{attrs:{"hide-details":"","outlined":"","dense":""},model:{value:(_vm.model.company),callback:function ($$v) {_vm.$set(_vm.model, "company", $$v)},expression:"model.company"}})],1)],1)],1),_c('v-divider')]:_vm._e(),_c('v-data-table',{attrs:{"loading":_vm.loading,"items":_vm.items,"headers":_vm.headers,"options":_vm.options,"group-by":_vm.groupBy},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var items = ref.items;
var headers = ref.headers;
return [((_vm.hasAccessTo('employee') || _vm.hasManyCompanies()) && items[0])?[_c('td',{attrs:{"colspan":headers.length}},[(items[0].company)?_c('v-icon',{staticClass:"mr-6"},[_vm._v("mdi-domain")]):_vm._e(),(items[0].company)?_c('strong',[_vm._v(_vm._s(items[0].company.name))]):_vm._e()],1)]:_vm._e()]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.name)?_c('span',[_vm._v(_vm._s(item.name))]):_c('v-icon',[_vm._v("mdi-minus")])]}},{key:"item.vehicles",fn:function(ref){
var item = ref.item;
return [(item.vehicles)?_c('span',[_vm._v(_vm._s(item.vehicles))]):_c('v-icon',[_vm._v("mdi-minus")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"ml-2",attrs:{"icon":"","to":{ name: 'panel.company-structure.edit', params: { id: item.id } }}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.hasAccessTo('employee'))?_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]}}],null,true)},[_c('v-list',{attrs:{"dense":"","subheader":""}},[_c('v-subheader',[_vm._v("Czy na pewno chcesz usunąć strukturę ("),_c('strong',[_vm._v(_vm._s(item.name))]),_vm._v(")?")]),_c('v-list-item',{on:{"click":function($event){}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Anuluj")])],1),_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-list-item',{staticClass:"error--text",on:{"click":function($event){return _vm.action.delete(item)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Usuń")])],1),_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-delete")])],1)],1)],1)],1)],1)]}}])})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }